<template>
  <div class="subscription-canceled-page">
    <div class="readable_content">
      <h4>{{ mainTitle }}</h4>
      <div v-if="deactivated">
        Your account has been downgraded to the <span class="bold">{{ freePlanLabel }}</span
        >. You will still be able to use your family tree, but will lose access to the records database.
      </div>
      <div v-else>
        Your current <span class="bold">{{ standardPlanLabel }}</span> is valid until
        <span class="bold">{{ expireDate }}</span
        >. After this, your account will be downgraded to the <span class="bold">{{ freePlanLabel }}</span
        >. You will still be able to use your family tree, but will lose access to the records database.
      </div>

      <p>
        If you change your mind, you can always re-activate your membership via your
        <router-link :to="accountPageRoute">Account page</router-link>.
      </p>
      <p><router-link :to="homePageRoute">&larr; Back to Home</router-link></p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  computed: {
    mainTitle() {
      if (this.$route.query.feedback === 'true') {
        return `Thanks for your feedback. Your membership has been cancelled.`;
      }
      return 'Your membership has been cancelled.';
    },
    expireDate() {
      return moment(decodeURIComponent(this.$route.query.expire_on)).format('MMMM DD, YYYY');
    },
    deactivated() {
      return this.$route.query.deactivated === 'true';
    },
    accountPageRoute() {
      return {name: 'user-profile'};
    },
    homePageRoute() {
      return {name: 'main-page'};
    },
    standardPlanLabel() {
      const sp = this.$route.query.sp || 'Detective';
      return `${sp} Membership`;
    },
    freePlanLabel() {
      const bp = this.$route.query.bp || 'Explorer';
      return `${bp} (Free) Plan`;
    },
  },
  name: 'SubscriptionCanceledPage',
};
</script>

<style scoped></style>

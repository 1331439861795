<template>
  <div class="share-link-page">
    <div class="readable_content">
      <mcr-loading-indicator :loading="loading"></mcr-loading-indicator>
      <div class="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      error: '',
    };
  },
  created() {
    this.$store
      .dispatch('resolveSubscriberLinkAction', {code: this.$route.params.code})
      .then(response => {
        if (response.redirect_url) {
          const redirectUrl = response.redirect_url.startsWith('http')
            ? response.redirect_url
            : `https://${response.redirect_url}`;
          window.location.replace(redirectUrl);
        } else if (response.redirect_path) {
          this.$router.push(response.redirect_path);
        } else {
          this.loading = false;
          this.error = 'Error while processing link. Please try again later.';
        }
      })
      .catch(() => {
        this.loading = false;
        this.error = 'Error while processing link. Please try again later.';
      });
  },
  name: 'ShareLinkPage',
};
</script>

<style scoped lang="scss"></style>

<template>
  <mcr-button :loading="loading" @click="click">
    <slot></slot>
  </mcr-button>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';

export default {
  props: {
    plan: Object,
    loading: Boolean,
    willSwitchToAnnual: Boolean,
  },
  methods: {
    click() {
      if (this.willSwitchToAnnual) {
        AnalyticsMainHandler.trackUpgradeStandardSubscriptionClick();
      } else {
        AnalyticsMainHandler.trackStartStandardSubscriptionClick();
      }
      if (!this.$store.getters.userIsLoggedInState) {
        const cleanQuery = {
          ...this.$route.query,
          billing_period: this.plan.billing_period,
          autostart_plan: this.plan.id,
        };
        const redirectUrl = this.$router.resolve({name: this.$route.name, query: cleanQuery}).href;
        this.$router.push({name: 'register', query: {redirect: redirectUrl}});
        return;
      }
      this.$emit('start-subscription', this.plan.id);
    },
  },
  components: {mcrButton},
  name: 'StartSubscriptionButton',
};
</script>

<style scoped></style>

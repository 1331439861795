<template>
  <div class="stand-out-block-list">
    <div class="wrapper" @touchstart="touchstart" @touchend="touchend" @mousedown="touchstart" @mouseup="touchend">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blocks: [],
      isScrolling: false,
      activeIndex: 1,
      touchStartX: null,
      touchStartY: null,
    };
  },
  mounted() {
    this.blocks = this.$slots.default;
    this.scrollToBlock(1);
  },
  methods: {
    touchstart(event) {
      if (!event.touches) {
        this.touchStartX = event.clientX;
        this.touchStartY = event.clientY;
      }
      if (event.touches && event.touches.length === 1) {
        this.touchStartX = event.changedTouches[0].pageX;
        this.touchStartY = event.changedTouches[0].pageY;
      }
    },
    getTouchEnd(event) {
      if (!event.touches) {
        return [event.clientX, event.clientY];
      }
      return [event.changedTouches[0].pageX, event.changedTouches[0].pageY];
    },
    touchend(event) {
      if (this.isScrolling || this.getSelectionText()) {
        return;
      }
      const [x, y] = this.getTouchEnd(event);
      const shiftX = this.touchStartX - x;
      const shiftY = this.touchStartY - y;
      if (shiftY > 20 || shiftY < -20) {
        return;
      }
      if (this.touchStartX && shiftX < -50) {
        this.scrollToPrevious();
      }
      if (this.touchStartX && shiftX > 50) {
        this.scrollToNext();
      }
    },
    getSelectionText() {
      if (window.getSelection) {
        return window.getSelection().toString();
      } else if (document.selection && document.selection.type !== 'Control') {
        return document.selection.createRange().text;
      }
    },
    scrollToPrevious() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1;
        this.scrollToBlock(this.activeIndex);
      }
    },
    scrollToNext() {
      if (this.activeIndex < this.blocks.length - 1) {
        this.activeIndex = this.activeIndex + 1;
        this.scrollToBlock(this.activeIndex);
      }
    },
    scrollToBlock(index) {
      const el = this.blocks[index].elm;
      const viewportWidth = window.innerWidth || window.screen.width;
      this.$scrollTo(el, 500, {
        container: '.wrapper',
        offset: -(viewportWidth - el.clientWidth) / 2,
        force: true,
        lazy: true,
        cancelable: false,
        x: true,
        onStart: element => {
          this.isScrolling = true;
        },
        onDone: element => {
          this.isScrolling = false;
        },
        onCancel: () => {
          this.isScrolling = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stand-out-block-list {
  .wrapper {
    display: flex;
    padding: 30px 20px 0;

    > .stand-out-block {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
    }

    > .stand-out-block:not(:first-child) {
      margin-left: 20px;
    }
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .wrapper {
      justify-content: center;
    }
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    .wrapper {
      overflow: hidden;
      > .stand-out-block {
        min-width: 60vw;
      }
    }
  }
}
</style>

<template>
  <div class="cny-banner" v-if="showBanner">
    <div class="cny-link">
      <img :src="$getAsset('/assets/promos/holiday2024-plans-banner.png')" class="cny-banner-img" height="234" />
      <div class="content-mobile">
        <div class="main-title">Enjoy 50% off</div>
        <div class="sub-title">with a Yearly Membership</div>
        <div class="sub-text">
          <div class="emoji"></div>
          <div class="text">Until Jan. 7, 2025.</div>
          <div class="emoji"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['featureSwitchesState', 'userIsSubscribedState']),
    showBanner() {
      return this.featureSwitchesState.HOLIDAY24_SALE && !this.userIsSubscribedState;
    },
  },
};
</script>

<style scoped lang="scss">
.cny-banner {
  background-color: $power-red;
  display: flex;
  justify-content: center;

  .cny-banner-img {
    width: 100%;
  }
  .content-mobile {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .cny-banner-img {
      display: none;
    }
    .content-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #f3e9e8;
      row-gap: 16px;
      padding: 48px 20px;

      .main-title {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: 0.005em;
        text-align: center;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }

      .sub-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0.005em;
        text-align: center;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }

      .sub-text {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .text {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.005em;
          text-align: center;
          color: rgba(243, 233, 232, 0.75);
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }

        .emoji {
          font-size: 28px;
          font-weight: 400;
          line-height: 34px;
        }
      }
    }
  }
}
</style>
